<template>
  <section>
    Your session link has expired
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AutoLogin",

  data() {
    return {
      loading: false,
      error: false,
    };
  },

  methods: { ...mapActions(["autoLogin"]) },
  async mounted() {
    const loginSucces = await this.autoLogin();
    loginSucces ? this.$router.push("my-dealers") : (this.error = true)
  },
};
</script>

<style>
</style>